/** @jsx jsx */
import { jsx } from "@emotion/core"
import Layout from "../components/layout"
import Card, { CardTitle } from "../components/card"

import Profile from "../components/profile"

export default function Contact() {
  return (
    <Layout>
      <Card>
        <CardTitle>Hi, I'm Leo!</CardTitle>
        <Profile />
        <h4>Thanks for your message!</h4>
        <p>I'll get back to you as soon as possible.</p>
      </Card>
    </Layout>
  )
}
